<template>
  <div class="my-groups">
    <MyBreadcrumb></MyBreadcrumb>
    <!-- <Group></Group> -->
    <div class="my-groups-list">
      <ClassManage :refresh="refresh" :status="curTabId" :isUser="true" :isManager="true" @create="addClass" />
    </div>
    <associatedData :show="assShow" @close="assClose()" />

  </div>
</template>

<script>
// import Group from './components/group';
import ClassManage from './components/classManage.vue';
import associatedData from './components/associatedData.vue';
export default {
  name: "myGroups",
  data() {
    return {
      refresh: Math.random(),
      assShow: false,
      expireList:[],
      rolesList:[],
      statusList:[],
      curTabId:'1',
    }
  },
  components: {
    // Group,
    ClassManage,
    associatedData,
  },
  created() {
    this.getForm();
  },
  methods: {
    getForm(){
      let params = {
        with_group_count:'1'
      };
      this.api.user.groupForm(params).then((res)=>{
        this.statusList = [];
        for(let name in res.statuses){
          let arr = res.group_status_count_list.filter((item)=>{
            return item.status == name;
          })
          let obj = {
            name:res.statuses[name],
            id:name
          };
          if(arr.length){
            obj.val = arr[0].count;
          }else{
            obj.val = 0;
          }
          this.statusList.push(obj)
        }
      })
    },
    changeTab(id){
      this.curTabId = id;
      console.log(this.curTabId,'this.curTabId')
    },
    addClass() {
      this.assShow = true;
    },
    assClose() {
      this.refresh = Math.random();
      this.assShow = false;
    },
  }
}
</script>

<style scoped lang="scss">
.my-groups {
  padding: 20px;

  .my-groups-list {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.4100);
    border-radius: 4px;

    .my-groups-list-top {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;
      .tab{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p{
          margin-right: 30px;
          padding-bottom: 10px;
          height: 100%;
          font-size: 16px;
          color: #666666;
          cursor: pointer;
          &.active{
            font-weight: bold;
            color: #4A79FF;
            border-bottom: 4px solid #5782FF;
          }
        }
      }
    }
  }
}
</style>
